<template>
  <painel-component :title="'Selecionar Conteúdo'">
    <ErrorComponent :errors="errors" v-if="showError"/>
    <div @click="$emit('close-modal-file')" class="btn-left">
      <img src="../../assets/icones/jump_left.svg">
    </div>

    <row-component>
      <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-10 col-sm-10 col-10'">
        <label>Nome do Aplicativo:</label>
        <input-component :type-input="'text'" v-model="formDate.name"/>
      </row-col-component>

      <row-col-component class="mt-4" :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 p-0'">
        <button-one :itens="buttonOne" @submit="submit"/>
      </row-col-component>
    </row-component>

    <div class="content-file-explorer">
      <div class="file-content" v-for="(file, index) in campaign_file.data" :key="index">
        <div class="file-link-div" :tooltip="file.name">
          <input :value="file.id" v-model="content_files" type="checkbox" name="thumbs" :id="'thumbs' + file.id">
          <label :for="'thumbs' + file.id">
            <img :src="require(`@/assets/img/${file.type}.png`)">
            <p>{{ file.name_original }}</p>
          </label>
        </div>
      </div>
    </div>

    <row-component class="button-add-bottom">
      <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
        <button-one :disabled="sendForm" style="width: 100%" :itens="buttonTwo" @submit="submitFile(content_files)"/>
      </row-col-component>
      <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
        <button-pagination style="height: 25px;" :pagination="campaign_file" :offset="3"
                           @paginate="loadCampaingsFiles"/>
      </row-col-component>
    </row-component>

  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonPagination from '@/components/buttons/ButtonPagination'
import {ACTION_MESSAGES} from '@/configs/messages';

export default {
  created() {
    this.loadCampaingsFiles(1)
    this.$store.dispatch('updateNamePage', 'Campanha')
  },

  props: {
    id: {required: true},
    folder_id: {required: true}
  },

  emits: ['close-modal-file', 'close-modal-file-submit'],

  name: 'CampaingFileSearch',
  components: {
    PainelComponent,
    ErrorComponent,
    RowColComponent,
    RowComponent,
    InputComponent,
    ButtonOne,
    ButtonPagination,

  },

  data() {
    return {

      buttonOne: {
        icon: 'search',
        tooltip: 'Buscar',
        type: 'button'
      },

      buttonAdd: {
        route: '/campaign/search',
        icon: 'jump_left',
        name: 'Voltar',
        tooltip: 'Voltar'
      },

      buttonTwo: {
        icon: 'plus',
        type: 'button',
        name: 'Adicionar',
        tooltip: 'Adicionar'
      },

      formDate: {
        id: this.id,
        content_folder_id: this.folder_id,
        name: '',
      },

      formDateAdd: {
        campaign_id: this.id,
        content_files: []
      },

      content_files: [],

      errors: [],
      showError: false,
      sendForm: false
    }
  },

  methods: {

    submit() {
      this.loadCampaingsFiles(1)
      this.searchSubmit = true;
    },

    submitFile(data) {
      data.forEach(element => {
        this.formDateAdd.content_files.push({
          content_file_id: element
        })
      });
      if (!this.sendForm) {
        this.sendForm = true
        this.$store.dispatch('storeCampaingsFile', this.formDateAdd)
            .then(() => {
              this.showError = false;
              this.sendForm = false
              this.errors = [];
              this.$emit('close-modal-file-submit', 1)
              this.$store.commit('addToast', ACTION_MESSAGES.postDefault)
            })
            .catch((r) => {
              this.showError = true;
              this.sendForm = false
              this.errors = r.response.data.errors;
              this.$store.commit('addToast', ACTION_MESSAGES.errorDefault)
            })
      }
    },

    loadCampaingsFiles(page) {
      if (!this.searchSubmit) {
        this.$store.dispatch('loadCampaingsFiles', {...this.params, page})
            .then((r) => {
              if (this.searchSubmit) {
                this.searchSubmit = false
                if (r.data == '') {
                  this.$store.commit('addToast', ACTION_MESSAGES.searchAlert)
                }
              }
            }).catch(() => {
          this.searchSubmit = false
        });
      }
    },
  },

  computed: {
    me() {
      return this.$store.state.auth.me
    },

    campaign_file() {
      return this.$store.state.campaign_file.items
    },

    params() {
      return {
        page: this.campaign_file.current_page,
        id: this.formDate.id,
        content_folder_id: this.formDate.content_folder_id,
        name: this.formDate.name,
      }
    },

  }

}
</script>

<style scoped>
.btn-left {
  top: 0;
  right: 0;
  margin: 2%;
  cursor: pointer;
  position: absolute;
}

.btn-left:hover {
  filter: invert(13%) sepia(0%) saturate(2000%) hue-rotate(167deg) brightness(94%) contrast(79%);
}

.btn-left img {
  width: 30px;
}

.content-file-explorer {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  min-height: 100%;
  border-radius: 0 0 10px 10px;
}

.file-content {
  font-size: 14px;
  position: relative;
  display: flex;
  height: fit-content;
  width: 105px;
  margin: 5px 10px;
  align-items: center;
  flex-direction: column;
  word-wrap: break-word;
  text-align: center;
}

.file-link-div {
  height: 100%;
  width: 100%;
}

.file-link-div > img {
  height: 80px;
}

.file-link-div p {
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-link-div:hover {
  cursor: pointer;
  background-color: rgb(192, 192, 192, 0.4);
}

input[type="checkbox"][id^="thumbs"] {
  display: none;
}

.file-link-div label {
  padding: 0 10px;
  cursor: pointer;
  width: 100%;
}

.file-link-div label img {
  height: auto;
  width: 100%;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

.file-content label:before {
    background-color: grey;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid grey;
    position: absolute;
    top: 4%;
    right: 1%;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 22px;
    z-index: 1;
    transition-duration: 0.4s;
    content: "✓";
    transform: scale(1);
}

.file-content input:checked + label:before {
    content: "✓";
    background-color: #2AB934;
    border: 1px solid #2AB934;
    transform: scale(1);
}

.file-link-div input[type="checkbox"] + label::after {
  display: none;
}

.button-add-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  margin: 0 auto;
}
</style>

<style scoped>
@media (max-width: 991px) {

  .file-content p {
    -webkit-line-clamp: 2;
  }
}

@media (max-width: 425px) {

  .content-file-explorer {
    justify-content: space-between;
  }

  .file-content {
    text-align: center;
    align-items: center;
    width: 85px;
    height: 110px;
  }

  .button-add-bottom {
    bottom: unset;
  }

  .file-content img {
    width: 60%;
  }

}
</style>